<template>
  <div>
    <base-sign-page
      title-text="角色"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      :loading="loading"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :need-pagination="true">
    </base-sign-page>
    <FmModal v-model="openDialog" title="角色" width="800px" theme="mh-withe">
      <FmForm v-if="chooseData" style="align-items: flex-start;" label-width="100px">
        <FmFormItem label="">
          <fm-tree placeholder="角色菜单" :key="treeKey" multiple v-loadingx="loading" :nodes="nodes" search is-checked @checked="onChecked"></fm-tree>
        </FmFormItem>
        <FmFormItem label="角色名">
          <FmInput required v-verifier @verifier="(msg) => verifier = msg" v-model="chooseData.name" placeholder="角色名"></FmInput>
        </FmFormItem>
      </FmForm>
      <div slot="footer">
        <fm-btn :disabled="verifier !== ''" @click="formSubmit">{{chooseData && chooseData.id ? '修改角色' : '新增角色'}}</fm-btn>
      </div>
    </FmModal>
    <!-- <fm-form-dialog
      form-title="角色"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseData"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog> -->
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  roleRequest as request
} from '@/api'

export default {
  components: {
    BaseSignPage
  },
  computed: {
    nodes () {
      const sysMenus = this.$store.getters.menuList.map(v => v.data)

      let fn = (menu) => {
        return {
          title: menu.name,
          data: menu,
          checked: this.checked.includes(menu.id)
        }
      }
      let result = sysMenus.filter(v => v.pid === 0).map(fn)
      let each = (nodes) => {
        nodes.forEach(node => {
          node.children = sysMenus.filter(v => v.pid === node.data.id).map(fn)
          each(node.children)
        })
      }
      each(result)
      return this.nodesKey > 0 ? result : []
     },
    tableActions: {
      get () {
        return [{
          label: '删除',
          key: 'delete'
        },
        {
          label: '修改',
          key: 'edit'
        }]
      }
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '角色名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'multipleChoose',
          label: '菜单',
          key: 'menuIds',
          selectDatas: this.$store.getters.menuList,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    titleMenus: {
      get () {
        return [{
          key: 'add',
          label: '新增'
        }]
      }
    },
    columnList: {
      get () {
        return [{
          field: 'name',
          title: '角色名'
        }]
      }
    }
  },
  created () {
    this.loadData()
    this.$store.dispatch('loadMenuList')
  },
  methods: {
    onChecked (item, data) {
      let menuIds = Array.from(new Set(data.map(({data}) => data.data.id)))
      this.$set(this.chooseData, 'menuIds', menuIds)
    },
    tableAction (data) {
      this.chooseData = data.data
      if (this.chooseData && this.chooseData.menus && this.chooseData.menus.length > 0) {
        const checked = this.chooseData.menus.reduce((result, v) => {
          return [...result, v.id, ...((v.children || []).map(v => v.id))]
        }, [])
        this.$set(this.chooseData, 'menuIds', checked)
        this.checked = [...checked]
      } else {
        this.checked = []
      }
      this.treeKey++
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'delete') {
        this.delData()
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除角色 ' + this.chooseData.name + ' 吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '角色已删除'
          })
          this.loadData()
        })
      }
    },
    formSubmit () {
      if (this.chooseData.id) {
        request.update(this.chooseData.id, {
          name: this.chooseData.name,
          menuIds: this.chooseData.menuIds
        }).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '角色修改完成'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        request.add({
          name: this.chooseData.name,
          menuIds: this.chooseData.menuIds
        }).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '角色新增完成'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (parm) {
      this.chooseData = null
      this.treeKey++
      if (parm === 'add') {
        this.chooseData = {name: '', menuIds: []}
        this.openDialog = true
      }
    },
    loadData () {
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        this.$store.dispatch('setRoleList', data)
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      verifier: '',
      dataList: [],
      checked: [],
      noteText: '',
      loading: true,
      chooseData: null,
      openDialog: false,
      treeKey: 0,
      nodesKey: 1
    }
  }
}
</script>